body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background-color: #3e3e3e;
}

p {
  font-family: 'Quicksand', sans-serif;
}

button {
  font-family: 'Quicksand', sans-serif;
}

a {
  font-family: 'Quicksand', sans-serif;
}

input {
  font-family: 'Quicksand', sans-serif;
}

textarea {
  font-family: 'Quicksand', sans-serif;
}

.dialogButton {
  border-radius: 5px;
  border: 2px solid #cecece;
  color: #cecece;
  outline: none;
  cursor: pointer;
  width: 100px;
  height: 40px;
  text-align: center;

  -webkit-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
}

.dialogButton p {
  margin: 0px auto;
  line-height: 40px;
}

.dialogButton:hover {
  border-radius: 20px;
  border: 2px solid #39b287;
  color: #39b287;
}

.dialogButtonSelected {
  width: 100px;
  height: 40px;
  text-align: center;
  outline: none;

  border-radius: 20px;
  border: 2px solid #39b287;
  background-color: #39b287;
  color: #3e3e3e;
}

.dialogButtonSelected p {
  margin: 0px auto;
  line-height: 40px;
}

.dialogButtonInactive {
  border-radius: 5px;
  border: 2px solid #777777;
  color: #777777;
  outline: none;
  width: 100px;
  height: 40px;
  text-align: center;
}

.dialogButtonInactive p {
  margin: 0px auto;
  line-height: 40px;
}


.dialogHeader {
  color: #cecece;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.dialogText {
  color: #cecece;
  line-height: 180%;
}

.mainBGColor {
  background-color: #3e3e3e;
}

.mainFGColor {
  color: #cecece;
}

.arrow {
  width: 20px;
  display: block;
  margin: 0px auto;
  margin-top: 80px;

  animation: bounce 2s 1s infinite;
}

.alignToolMenu {
  float: left;
}

.alignToolMenuMobile {
  visibility: hidden;
  display: none;
  position: relative;
}

.alignToolMenuMobileDropdown {
  display: none;
  position: absolute;
  background-color: #333333;
  color: #cecece;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.alignToolMenuMobileIcon {
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 2px solid #3e3e3e;
  cursor: pointer;
  margin-right: 10px;
  display: block;
  margin: 0px auto;

  -webkit-transition: border-color 0.2s ease;
  -moz-transition: border-color 0.2s ease;
  -o-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}

.alignToolMenuMobileIcon:hover {
  border: 2px solid #3e3e3e;
}

.align-right div {
  text-align: right;
  /* margin-bottom: 20px; */
}

.align-center div {
  text-align: center;
  /* margin-bottom: 20px; */
}

.align-left div {
  text-align: left;
  /* margin-bottom: 20px; */
}

.align-justify div {
  text-align: justify;
  /* margin-bottom: 20px; */
}

.bgToolMenu {
  float: left;
}

.bg-dropdown-content {
  display: none;
  position: absolute;
  background-color: #333333;
  color: #cecece;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.bgColorContainer {
  height: 40px;
  width: 90%;
  margin: 0px auto;
  cursor: pointer;
}

.bgColorContainerSelected {
  height: 36px;
  width: 90%;
  margin: 0px auto;
  border: 2px solid #39b287;
  cursor: pointer;
}

.bgColorContainer:hover {
  height: 36px;
  border: 2px solid #39b287;
}

.bgHeader {
  color: #cecece;
  margin-left: 10px;
  margin-bottom: 0px;
}

.bulletToolMenu {
  float: left;
  margin-left: 10px;
}

.cardImageContainer {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 70%;
  display: block;
  margin: 0px auto;
  height: 100vh;
  cursor: pointer;
}

.container {
  width: 55%;
  margin: 0px auto;
  text-align: left;
}

.contentTextArea {
  width: 100%;
  min-height: 200px;
  outline: none;
  border: none;
  margin-bottom: 100px;
  padding: 3px;

  font-size: 20px;
  line-height: 180%;
  z-index: inherit;
}

.colorsHeader {
  color: "#cecece";
  margin-left: 2px;
}

.colorBox {
  width: 20px;
  height: 20px;
  float: left;
  cursor: pointer;
  margin: 2px;
}

.colorBox:hover {
  border: 2px solid #39b287;
  width: 16px;
  height: 16px;
}

.colorsContainer {
  width: 90%;
  margin: 0px auto;
}

.customTextToolMenu {
  margin-top: 4px;
  margin-left: -5px;
  margin-right: 10px;
  float: left;
}

.customTextPreviewContainer {
  width: 90%;
  margin: 0px auto;
  margin-top: 10px;
  height: 50px;
  line-height: 50px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.customTextPreviewText {
  vertical-align:middle;
  margin-left: 5px;
  padding: 5px;
}

.centerIcons {
  margin: 0px auto;
  display: block;
  width: 15%;
}

.copiedMessage {
  color: #39b287;
  margin-top: 5px;
  font-style: italic;
}

.declineButton {
  border-radius: 5px;
  border: 2px solid #cecece;
  color: #cecece;
  outline: none;
  cursor: pointer;
  width: 100px;
  height: 40px;
  text-align: center;

  -webkit-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
}

.declineButton p {
  margin: 0px auto;
  line-height: 40px;
}

.declineButton:hover {
  border-radius: 20px;
  border: 2px solid #cc5252;
  color: #cc5252;
}

.drawingToolMenu {
  float: left;
}

/* Dropdown Button */
.dropbtn {
  background-color: #3e3e3e;
  color: #cecece;
  font-size: 20px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: -2px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333333;
  color: #cecece;
  min-width: 320px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
/* .dropdown-content a {
  display: block;
  color: #cecece;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
} */

/* Change color of dropdown links on hover */
/* .dropdown-content a:hover {
  background-color: #2b2b2b;
  border: 2px solid #eb2f39;
} */

.draftJsEmojiPlugin__emojiSelect__34S1B {
  display: inline-block;
  float: left;
  margin-top: 5px;
  margin-right: 10px;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  margin: 0;
  padding: 0;
  line-height: 1.2em;
  font-size: 1.2em;
  color: #888;
  background: transparent;
  cursor: pointer;
  border: none;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:focus, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:focus {
  outline: 0;
  /* reset for :focus */
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:hover, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:hover {
  color: #39b287;
}

.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  color: #39b287;
}

.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  margin-top: 10px;
  padding: 0 .3em;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #333333;
  box-shadow: 0 4px 30px 0 rgba(0,0,0,0.2);
}

.draftJsEmojiPlugin__emojiSelectPopoverClosed__3Kxxq {
  display: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverTitle__3tpXz {
  margin: 0 0 .3em;
  padding-left: 1em;
  height: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
  margin: 0 0 .3em;
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE {
  padding: 0 .5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE:first-child .draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
  display: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
  margin: 1em 0;
  padding-left: .5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupList__HQ8_y {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupItem__2pFOS {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelect__28bny {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectList__haFSJ {
  margin: .3em;
  padding: .3em;
  position: absolute;
  display: -webkit-box;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: .5em;
  background: #fff;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL:first-child {
  border-right: 1px solid #e0e0e0;
}

.draftJsEmojiPlugin__emojiSelectPopoverEntry__1ErDJ, .draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;

  -webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  -moz-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  -o-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  background-color: #3e3e3e;
  cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectPopoverEntryIcon__1yNaC {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  padding: 10px;
}

.draftJsEmojiPlugin__emojiSelectPopoverNav__1Nzd7 {
  margin: 0;
  padding: 0 .5em;
  display: -webkit-box;
  display: flex;
  width: 20em;
  list-style: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavItem__qydCX {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavEntry__1OiGB, .draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
  color: #39b287;
}

.draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
  position: absolute;
  right: .2em;
  top: .3em;
  bottom: .3em;
  width: .25em;
  background-color: #e0e0e0;
  border-radius: .125em;
  opacity: .1;
}

.draftJsEmojiPlugin__emojiSelectPopoverScrollbarThumb__jGYdG {
  background-color: #39b287;
  border-radius: .125em;
  cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m:hover .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
  opacity: .3;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:hover,
.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:active {
  opacity: .6;
}
.draftJsEmojiPlugin__emoji__2oqBk {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;

  /*
  We need to limit the emoji width because it can be multiple characters
  long if it is a 32bit unicode. Since the proper width depends on the font and
  it's relationship between 0 and other characters it's not ideal. 1.95ch is not
  the best value, but hopefully a good enough approximation for most fonts.
  */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  line-height: inherit;
  margin: -.2ex 0em .2ex;
  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;

  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em;
}
.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_ {
  padding: 5px 10px 1px 10px;

  -webkit-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  -moz-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  -o-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_:active {
  background-color: #3e3e3e;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
  background-color: #3e3e3e;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryText__2sPjk {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryIcon__1qC2V {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  padding-top: 10px;
  float: left;
}
.draftJsEmojiPlugin__emojiSuggestions__2ffcV {
  margin-top: 1.75em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #333333;
  box-shadow: 0px 4px 30px 0px rgba(0,0,0,0.2);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.draftJsFocusPlugin__unfocused__1Wvrs:hover {
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #39b287;
}

.draftJsFocusPlugin__focused__3Mksn {
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #39b287;
}

.dialogContent {
  padding: 50px;
}

#editor img {
  max-width: 90%;
  z-index: 100;
  margin-right: 10px;
  margin-left: 0px;
  position: relative;
}

#editor figure {
  cursor: pointer;
  margin: 0px;
}

#editor iframe {
  min-width: 80%;
  min-height: 300px;
  margin: 0px auto;
  display: block;
}

.emojiContainer {
  float: left;
  margin-left: -2px;
}

.emailFormInput {
  padding: 10px;
  outline: none;
  border: 2px solid #333333;
  color: #cecece;
  background-color: #3e3e3e;
  font-size: 18px;
  width: 100%;
  margin-top: 20px;

  -webkit-transition: border-color 0.1s ease;
  -moz-transition: border-color 0.1s ease;
  -o-transition: border-color 0.1s ease;
  transition: border-color 0.1s ease;
}

.emailFormInput:focus {
  border: 2px solid #39b287;
}

.fileToolMenu {
  float: left;
  margin-left: 10px;
}

.footer {
  background-color: #333333;
  border-top: "1px solid #000000";
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}

.fontsContainer {
  width: 90%;
  margin: 0px auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fontBox {
  height: 20px;
  cursor: pointer;
  margin: 0px auto;
  padding: 15px;
}

.fontLabel {
  margin-left: 10px;
  margin: 0px auto;
}

.fontBox:hover {
  background-color: #3e3e3e;
}

.feedbackContainer {

}

.feedbackColorContainer {
  width: 100%;
  height: 20px;
}

.feedbackDivider {
  height: 2px;
  background-color: #333333;
  margin-top: 20px;
}

.feedbackSender {
  color: #898989;
  font-style: italic;
}

.feedbackContent {
  color: #cecece;
}

.generalFeedbackTextArea {
  background-color: #3e3e3e;
  border: 2px solid #333333;
  width: 100%;
  height: 100px;
  outline: none;
  color: #cecece;
  font-size: 18px;
  padding: 10px;
  margin-top: 20px;
  resize: none;

  -webkit-transition: border-color 0.3s ease;
  -moz-transition: border-color 0.3s ease;
  -o-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}

.generalFeedbackTextArea:focus {
  border: 2px solid #39b287;
}

.highlightColorsContainer {
  width: 45%;
  float: right;
}

.image-upload {
  float: left;
}

.image-upload>input {
  display: none;
}

.linkToolMenu {
  float: left;
}

.link {
  cursor: pointer;
  color: #55b8c9;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 2px solid #55b8c9;
}

.linkContainer {
  height: 30px;
  padding: 10px;
  background-color: #55b8c9;
  line-height: 0px;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  cursor: pointer;

  -webkit-transition: border-radius 0.3s ease;
  -moz-transition: border-radius 0.3s ease;
  -o-transition: border-radius 0.3s ease;
  transition: border-radius 0.3s ease;
}

.mobileLinkContainer {
  display: none;
}

.linkContainer:hover {
  /* border-radius: 10px; */
}

.logo {
  font-size: 30px;
  color: #39b287;
  font-weight: bold;
  margin-bottom: 0px;
  display: inline-block;
  font-family: 'Lora', sans-serif;
}

.logoSubtext {
  font-size: 20px;
  color: #cecece;
}

.mainPageOpeningContainer {
  height: 100vh;
  background-image: url('/images/mainBackground-01.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.mainPageTopPadding {
  padding-top: 5vh;
}

.mobileMessageContainer {
  visibility: hidden;
}

.mobileButton {
  margin-left: 20px;
}

.nonEditorContainer {
  margin-top: 50px;
  width: 80%;
}

.navbar {
  margin-bottom: 20px;
}

.newCardButton {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #cecece;
  color: #cecece;
  cursor: pointer;
  margin-right: 20px;
  outline: none;
  font-size: 15px;

  -webkit-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
}

.newCardButton:hover {
  border-radius: 20px;
  border: 2px solid #39b287;
  background-color: #39b287;
  color: #39b287;
}

.notificationText {
  color: #cecece;
  text-align: center;
}

.openCardContainer {
  width: 200px;
  height: 200px;
  position: absolute;;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.previewButton {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #cecece;
  color: #cecece;
  cursor: pointer;
  outline: none;
  margin-right: 20px;
  font-size: 15px;

  -webkit-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
}

.previewButton:hover {
  border-radius: 20px;
  border: 2px solid #39b287;
  background-color: #39b287;
  color: #39b287;
}

.patchHeader {
  cursor: pointer;
  display: inline-block;
  padding-bottom: 2px;
  margin-bottom: 4px;
  border-bottom: 2px solid #3e3e3e;
  font-size: 25px;

  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  -moz-transition: color 0.1s ease, border-color 0.1s ease;
  -o-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.patchHeader:hover {
  color: #39b287;
  border-bottom: 2px solid #39b287;
}

.password {
  color: #3e3e3e;
}

.passwordIncorrectMsg {
  color: #cc5252;
  font-size: 12px;
}

.passwordContainer {
  height: 30px;
  padding: 10px;
  background-color: #39b287;
  line-height: 0px;
  display: inline-block;
  cursor: pointer;
}

.formInput {
  padding: 10px;
  outline: none;
  border: 2px solid #cecece;
  color: #cecece;
  background-color: #3e3e3e;
  font-size: 18px;
  width: 80%;
}

.formInput:focus {
  border: 2px solid #39b287;
}

.inputSubmitButton {
  height: 45px;
  width: 100px;
  outline: none;
  border-radius: 5px;
  background-color: #3e3e3e;
  border: 2px solid #cecece;
  color: #cecece;
  cursor: pointer;
  font-size: 15px;

  -webkit-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
}

.inputSubmitButton:hover {
  border-radius: 20px;
  border: 2px solid #39b287;
  color: #39b287;
}

.inputSubmitButtonInactive {
  height: 45px;
  width: 100px;
  outline: none;
  border-radius: 5px;
  background-color: #3e3e3e;
  border: 2px solid #777777;
  color: #777777;
  cursor: default;
  font-size: 15px;
}

.inputExtraInfo {
  color: #6b6b6b;
  font-style: italic;
}

.sectionHeader {
  color: #cecece;
  font-size: 24px;
  border-bottom: 4px solid #333333;
  padding-bottom: 10px;
  margin-bottom: 0px;
  font-weight: bold;
}

.shareButton {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #cecece;
  color: #cecece;
  cursor: pointer;
  outline: none;
  font-size: 15px;

  -webkit-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
}

.shareButton:hover {
  border-radius: 20px;
  border: 2px solid #39b287;
  background-color: #39b287;
  color: #39b287;
}

.shareSocialMediaButton {
  cursor: pointer;
  border: 4px solid #3e3e3e;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 0px;

  -webkit-transition: border-color 0.3s ease;
  -moz-transition: border-color 0.3s ease;
  -o-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}

.shareSocialMediaButton:hover {
  border: 4px solid #333333;
}

.sizeBox {
  width: 48%;
  height: 40px;
  float: left;
  background-color: #333333;
  cursor: pointer;
  text-align: center;
}

.sizeBox:hover {
  background-color: #3e3e3e;
}

.sizeLabel {
  padding: 10px;
  margin: 0px auto;
}

.sigCanvas {
  border: 4px solid #333333;
  margin-top: 20px;
  width: 100%;
  height: 300px;
}

.stickerToolMenu {
  float: left;
  position: relative;
}

.stickerToolsDropdown {
  display: none;
  position: absolute;
  background-color: #333333;
  color: #cecece;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  min-width: 400px;
  z-index: 1;
}

.stickersContainer {
  width: 90%;
  margin: 0px auto;
}

.stickersHeader {
  color: #cecece;
  margin-left: 10px;
  margin-bottom: 0px;
  font-weight: bold;
}

.stickerImage {
  max-width: 100px;
  float: left;
  cursor: pointer;
  margin: 10px;
}

.stickerImage:hover {
  background-color: #3e3e3e;
}

.stickyHeader {
  position: absolute;
  z-index: 10;
  width: 55%;
}

.stampImage {
  width: 22%;
  float: left;
  cursor: pointer;
  margin-right: 10px;

  border: 2px solid #3e3e3e;

  -webkit-transition: background-color 0.1s ease;
  -moz-transition: background-color 0.1s ease;
  -o-transition: background-color 0.1s ease;
  transition: background-color 0.1s ease;
}

.stampImage:hover {
  background-color: #333333;
}

.stampImageSelected {
  width: 22%;
  float: left;
  cursor: pointer;
  margin-right: 10px;

  border: 2px solid #39b287;
}

.stampAnimation {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 67%;
  width: 10%;
  transform: translate(50%, 50%);
  cursor: pointer;

  -webkit-transition: transform 0.1s ease;
  -moz-transition: transform 0.1s ease;
  -o-transition: transform 0.1s ease;
  transition: transform 0.1s ease;
}

.stampAnimation:hover {
  transform: translate(50%, 50%) rotate(-10deg);
}

.stampAnimationText {
  text-align: center;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.scrollUpAnimation {
  right: 20px;
}

.templatesContainer {
}

.templateBox {
  width: 46%;
  float: left;
  margin-bottom: 40px;
}

.templateTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #cecece;
}

.templateDescription {
  color: #cecece;
  margin-top: 5px;
  height: 35px;
  font-size: 15px;
}

.templateImageBox {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80%;
  height: 200px;
  border: 6px solid #333333;
  box-shadow: 10px 10px #333333;
  cursor: pointer;

  -webkit-transition: 0.1s ease-in-out;
 -moz-transition: 0.1s ease-in-out;
 -o-transition: 0.1s ease-in-out;
 transition: 0.1s ease-in-out;
}

.templateImageBox:hover {
  -webkit-transform: translate(-0.5em,-0.5em);
  -moz-transform: translate(-0.5em,-0.5em);
  -o-transform: translate(-0.5em,-0.5em);
  -ms-transform: translate(-0.5em,-0.5em);
  transform: translate(-0.5em,-0.5em);
}

.textToolMenu {
  float: left;
}

.textColorsContainer {
  width: 45%;
  float: left;
}

.textSizeContainer {
  width: 90%;
  margin: 0px auto;
  margin-top: 10px;
}

.titleTextArea {
  width: 100%;
  outline: none;
  border: none;
  margin-bottom: 20px;
  padding: 3px;

  font-size: 30px;
  font-weight: bold;
}

.toolMenu {
  width: 100%;
  margin: 0px auto;
}

.toolMenuIcon {
  width: 20px;
  height: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-right: 5px;
  margin-left: -2px;
  cursor: pointer;
  margin-right: 10px;
}

.tutorialButton {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #cecece;
  color: #cecece;
  cursor: pointer;
  outline: none;
  margin-right: 20px;
  font-size: 15px;

  -webkit-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
  transition: background-color 0.3s ease, color 0.1s ease, border-radius 0.3s ease;
}

.tutorialButton:hover {
  border-radius: 20px;
  border: 2px solid #39b287;
  background-color: #39b287;
  color: #39b287;
}

.tutorialTitle {
  font-size: 30px;
  font-weight: bold;
  color: #cecece;
  margin: 0px auto;
  margin-top: 8px;
  margin-bottom: 10px;
}

.tutorialVideoContainer {
  overflow:hidden;
  display:block;
  height: 285px;
}

.tutorialVideo {
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tutorialText {
  color: #cecece;
  line-height: 30px;
}

.termsListContainer {
  margin-top: 10px;
  margin-bottom: 00px;
}

.termsListItem {
  margin-top: 5px;
  color: #b6b7b7;
}

.warningContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.warningMessage {
  color: #cecece;
  font-size: 20px;
}

.writeCapsuleButton {
  width: 200px;
  border: 2px solid #39b287;
  color: #39b287;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

@media only screen and (max-width: 1100px) {
  .alignToolMenuWeb {
    visibility: hidden;
    display: none;
  }

  .alignToolMenuMobile {
    visibility: visible;
    display: inline-block;
  }

  .templateBox {
    width: 100%;
    float: none;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    width: 90%;
  }

  .stickyHeader {
    width: 90%;
  }

  .warningContainer img {
    width: 30%;
  }
}

@media only screen and (max-width: 500px) {
  .textColorsContainer {
    width: 90%;
    float: none;
    margin-bottom: 70px;
  }

  .highlightColorsContainer {
    width: 90%;
    float: none;
  }

  .colorsHeader {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .dropdown-content {
    min-width: 180px;
  }

  .sizeBox {
    float: none;
    display: block;
    width: 100%;
    text-align: left;
  }

  .colorsHeader {
    margin-bottom: 5px;
  }

  /* .fileToolMenu {
    visibility: hidden;
    display: none;
  } */

  .emojiContainer {
    display: none;
    visibility: hidden;
  }

  .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
    max-width: 100px;
  }

  .mobileButton {
    margin-left: 0px;
  }

  .previewButton {
    display: none;
  }

  .tutorialButton {
    display: none;
  }

  .linkContainer {
    display: none;
  }

  .mobileLinkContainer {
    display: block;
    background-color: #55b8c9;
    width: 100%;
    padding: 5px;
    font-size: 14px;
  }

  .termsListContainer {
    padding-left: 0px;
  }

  .drawingToolMenu {
    display: none;
  }

  .writeCapsuleButton {
    width: 140px;
  }

  /* .mobileMessageContainer {
    visibility: visible;
  }

  .desktopContainer {
    visibility: hidden;
    display: none;
  } */
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
      -webkit-transform: translateY(0px);
    	-moz-transform: translateY(0px);
        transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-20px);
    	-moz-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    60% {
      -webkit-transform: translateY(-12px);
    	-moz-transform: translateY(-12px);
        transform: translateY(-12px);
    }
}
